<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          All Consolidated Payments
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link to="/invoices/add-consolidated-payment">
          <v-btn small>Add Consolidated Payment</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cp, index) in consolidatedPayments"
                :key="index"
              >
                <td>{{ cp.customer.name }}</td>
                <td>{{ cp.created | tinyDate }}</td>
                <td>
                  <router-link :to="`/invoices/draft-consolidated-payment/${cp.id}`">
                    <v-btn x-small>View</v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesConsolidatedPayments',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      consolidatedPayments: [],
      page: 1,
      length: 0,
      totalVisible: 9,
    };
  },
  watch: {
    page() {
      this.getConsolidatedPayments();
    },
  },
  methods: {
    getConsolidatedPayments() {
      axios.get(`/consolidatedPayments/getAll/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.consolidatedPayments = response.data.consolidatedPayments;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getConsolidatedPayments();
  },
};
</script>
